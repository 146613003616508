import React from 'react'
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { BtnWrapper, FormWrapper, BoxWrapper, BoxinputWrapper} from "./form.style";
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'
import Rightimg from '../img/blackbg.png';
import Logo from '../img/formlogo.png';
import { FormpageWrapper }from './form.style';
import Icon from 'react-icons-kit';
import {ic_keyboard_arrow_right} from 'react-icons-kit/md/ic_keyboard_arrow_right';
import {ic_keyboard_arrow_left} from 'react-icons-kit/md/ic_keyboard_arrow_left';
import Spinner from 'react-bootstrap/Spinner';
import { Link, navigate } from 'gatsby';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const element = (
  <Spinner animation="border" size="sm" />
);

const animationblock = (
  <div className="animation-block"><Spinner animation="border" size="sm" /></div>
);

class FormSix extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      plan:  '',
      planqty: '',
      userexpected: '', 
      invoicesinmonth: '',
      email: '',
      telefoonnummer: '',
      bedrijfsnaam: '',
      contactpersoon: '',
      currentStep: 5,
      renderloading: false,
      renderload: false,
    }
  }

  handleChange = event => {
    const {name, value} = event.target
      this.setState({
        [name]: value
      })    
  }

  componentDidMount() {
    console.log(window.history.state)
    if(window.history.state.plan) {
      this.setState({
        plan: window.history.state.plan, planqty: window.history.state.planqty, userexpected: window.history.state.userexpected, invoicesinmonth: window.history.state.invoicesinmonth
      })
    }else {
      navigate('/');
    }
  }

  render() {
    const data = this.props;
    return (
      <React.Fragment>
        <FormpageWrapper>
          <Row>
            <Container fluid={true}>
            {this.state.renderload ? (<span> {animationblock} </span>) : ""}
              <Row>
                <Col xs="12" sm="9">
                  <div className="left-block">
                      <Row className="justify-content-md-center">
                        <Col xs="12" sm="6">
                            <div className="form-block">

                              <div className="logo">
                                <Img fluid={data.formData.logo.fluid} />
                              </div>
                              <form name="contact" action="/success" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                               <input type="hidden" name="bot-field" />
                               <input type="hidden" name="form-name" value="contact" />
                               <input className="hiddenfiled" type="text" name="plan" value={this.state.plan} />
                               <input className="hiddenfiled" type="text" name="planqty" value={this.state.planqty}  />
                               <input className="hiddenfiled" type="text" name="userexpected" value={this.state.userexpected} />
                               <input className="hiddenfiled" type="text" name="invoicesinmonth" value={this.state.invoicesinmonth}/>
                                <Step6 
                                  currentStep={this.state.currentStep} 
                                  handleChange={this.handleChange}
                                  email={this.state.email}
                                  telefoonnummer={this.state.telefoonnummer}
                                  bedrijfsnaam={this.state.bedrijfsnaam}
                                  contactpersoon={this.state.contactpersoon}
                                  formData={data.formData}
                                />
                              </form>
                            </div>
                        </Col>
                      </Row>
                  </div>
                </Col>
                <Col xs="12" sm="3">
                  <div className="right-block">
                    <div className="blackbox">
                     <h3 className="sidebartitle">{data.sidebar.homepageFormTitle}</h3>
                       <div className="sidebarimages">
                         <ul>
                              {data.sidebar.formImages.map((items, i) => (
                                 <li>
                                  <Img fluid={items.fluid} />
                                </li>
                              ))} 
                          </ul>
                       </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </FormpageWrapper>
      </React.Fragment>
    );
  }
}

function Step6(props) { 
  return(
    <React.Fragment>
      <div className="stepfive">
        <div className="stepthead">
          <div
            dangerouslySetInnerHTML={{ __html: documentToHtmlString(props.formData.step6Description.json) }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="userdetail"></label>
          <input
            className="form-control"
            name="email"
            type="email"
            placeholder="Email"
            value={props.email}
            onChange={props.handleChange}
            required/>  
            <label htmlFor="Telefoonnummer"></label>
          <input
            className="form-control"
            name="telefoonnummer"
            type="text"
            placeholder="Telefoonnummer"
            value={props.telefoonnummer}
            onChange={props.handleChange}
            required/>  
             <label htmlFor="userdetail"></label>
          <input
            className="form-control"
            name="bedrijfsnaam"
            type="text"
            placeholder="Bedrijfsnaam"
            value={props.bedrijfsnaam}
            onChange={props.handleChange}
            /> 
             <label htmlFor="Contactpersoon"></label>
          <input
            className="form-control"
            name="contactpersoon"
            type="text"
            placeholder="Contactpersoon"
            value={props.contactpersoon}
            onChange={props.handleChange}
            />     
        </div>
        <div className="submit-text">
            <p>Bij de afronding van de signup, gaat u akkoord met de voorwaarden van <br /><Link to="/">Boekhounder.nl</Link>,  <Link to="/">Terms of Service</Link>, <Link to="/privacystatement">Privacy Policy</Link> en <Link to="/">Cookie Policy</Link></p>
            <button className="btn btn-custom">{props.formData.step6ButtonText}</button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FormSix;
